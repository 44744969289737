import React, { useContext, useState, useEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import Layout from "../../components/Layout";
import PlainPage from "../../templates/plain-page";
import {
  firebaseApp,
  gglSignup,
  fbSignup,
} from "../../components/firebase/firestore";

const handleLogin = async event => {
  event.preventDefault();

  const button = document.getElementById("login-btn");
  button.classList.add("is-loading");

  const { email, password } = event.target.elements;

  try {
    await firebaseApp
      .auth()
      .signInWithEmailAndPassword(email.value, password.value);
    navigate("/ref-users");
  } catch (error) {
    alert(`Error logging in. ${error.message}`);
    button.classList.remove("is-loading");
  }
};

const Content = props => {
  const { currentUser } = props;

  useEffect(() => {
    if (currentUser) {
      navigate("/ref-users");
    }
  }, [currentUser]);

  return (
    <div className="column is-5-tablet is-5-desktop is-4-widescreen">
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Login
      </h2>
      <div className="box">
        <form action="" onSubmit={handleLogin}>
          <div className="field">
            <label htmlFor="" className="label">
              Email
            </label>
            <div className="control has-icons-left">
              <input
                id="email"
                type="email"
                placeholder="e.g. jonhdoe@gmail.com"
                className="input"
                name="email"
                autoComplete="email"
                required
              />
              <span className="icon is-small is-left">
                <i className="fa fa-envelope" />
              </span>
            </div>
          </div>
          <div className="field">
            <label htmlFor="" className="label">
              Password
            </label>
            <div className="control has-icons-left">
              <input
                type="password"
                placeholder="*******"
                className="input"
                name="password"
                autoComplete="current-password"
                required
              />
              <span className="icon is-small is-left">
                <i className="fa fa-lock" />
              </span>
            </div>
          </div>
          <a
            onClick={() => {
              const email = prompt(
                "Enter the email address you registered with.",
                document.getElementById("email").value,
              );
              if (email !== null) {
                const actionCodeSettings = {
                  url: "https://occasio.online/login",
                };
                firebaseApp
                  .auth()
                  .sendPasswordResetEmail(email, actionCodeSettings);
                alert(
                  "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
                );
              }
            }}
          >
            Forgot your password?
          </a>
          <div className="field is-grouped is-grouped-right">
            <button id="login-btn" className="button is-success">
              Log in
            </button>
          </div>
        </form>
        <div className="is-divider" data-content="OR" />
        <div className="columns has-text-centered">
          <div className="column">
            <div className="field">
              <a
                id="ggl-signup"
                className="button is-black"
                style={{ backgroundColor: "#dd4b39" }}
                onClick={gglSignup}
              >
                <span className="icon">
                  <i className="fab fa-google" />
                </span>
                <span>Log in with Google</span>
              </a>
            </div>
            <div className="field">
              <a
                id="fb-signup"
                className="button is-black"
                style={{ backgroundColor: "#3b5998" }}
                onClick={fbSignup}
              >
                <span className="icon">
                  <i className="fab fa-facebook-f" />
                </span>
                <span>Log in with Facebook</span>
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="has-text-centered">
        Access and use of this site is strictly limited to people over the age
        of 18 years of age. By creating an account and using the site, you are
        attesting you are at least 18 years of age and agree to our{" "}
        <Link to="/terms">Terms&nbsp;&&nbsp;Conditions</Link>
        .
        <br />
        <br />
        To find out what personal data we collect and how we use it, please
        visit our <Link to="/privacy">Privacy Policy</Link>.
      </div>
    </div>
  );
};

const LoginPage = () => {
  return <PlainPage Content={Content} isCentred />;
};

export default LoginPage;
